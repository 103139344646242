.mapfre-container {
  width: 80%;
  margin: 0 auto;
  font-family: "Raleway";
}

.especialidad-container {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  //height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.form-container {
  padding: 1.2rem 1.5rem;
  background-color: white;
  box-shadow: var(--elevacion-3);
  max-width: 380px;
  border-radius: 0.6rem;
  color: black;

  .mat-form-field {
    width: 100%;
  }
}

.white-container {
  width: 500px;
  margin: 17px;
  background-color: white;
  border-radius: 1rem;
  box-shadow: var(--elevacion-3);
  padding: 1rem;
}

.valor-seguro-container {
  background-color: #ffb507;
  padding: 1rem 2rem;
  width: 20rem;
  border-radius: 55px;

  p,
  h1 {
    margin: 0;
  }
}

.resumen-container {
  text-align: center;
  p {
    margin: 0;
  }
}
.resumen-section-uno,
.resumen-section-dos {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.resumen-section {
  padding-top: 1rem;
}

.custom-dialog-container {
  max-height: 80vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
  }
}

.flex-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
}

@media screen and (min-width: 769px) and (max-width: 1300px) {
  .flex-container {
    width: 100%;
  }
}

@media screen and (min-width: 451px) and (max-width: 768px) {
  .flex-container {
    width: 100%;
  }
  .form-container {
    width: 90%;
    padding: 0.8rem;
    margin-bottom: 30px;
  }

  .form-container.resumen {
    width: 500px;
    box-shadow: var(--elevacion-1);
  }

  .custom-dialog-container {
    min-width: 280px;
    margin: 0 auto;
    max-height: 90vh;

    .mat-dialog-container {
      padding: 0.8rem;
    }

    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 450px) {
  .flex-container {
    width: 95%;
    margin: 0 auto;
  }

  .mapfre-container {
    width: 100%;
  }

  .form-container {
    min-width: 290px;
    max-width: 290px;
    padding: 1rem;
    margin-bottom: 10px;
  }

  .white-container {
    min-width: 320px;
    max-width: 320px;
    margin: 17px auto;
  }
}
@media screen and (max-width: 350px) {
  .form-container {
    min-width: 270px;
    max-width: 270px;
  }

  .white-container {
    min-width: 280px;
    max-width: 280px;
  }
}
