@import "global.scss";
@import "grid.scss";
@import "colores-sombras.css";
@import "components/carousel.scss";
@import "components/container.scss";
@import "components/title.scss";
@import "components/buttons.scss";
@import "components/navigation.scss";
@import "components/footer.scss" ;
@import "components/form.scss";
@import "views/adjuntar-documentos.scss";
@import "views/error-page.scss";
@import "views/identificacion.scss";
@import "views/step.scss";
@import "views/home.scss";
@import "views/mostrar-cotizacion-resumen.scss";
@import "../font/icon-font/icon-font.css";