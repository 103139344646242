/* ============= bahamon.dev ============== */

    /* BUTTONS */
        ._btn._1 {
            border-radius: 9rem;
            padding: 0.7rem 2.5rem;
            background: #D81E04;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            font-size: 1rem;
            line-height: 1;
            display: block;
            width: max-content;
            border:1px solid transparent;
        }
        ._whatsapp_fx img {
            width: 3rem;
            height: 3rem;
            object-fit: contain;
        }

        ._whatsapp_fx {
            position: fixed;
            bottom: 4vw;
            left: 4vw;
            z-index: 3;
            display: block;
        }
        button.mat-focus-indicator._btn._1.mat-raised-button.mat-button-base.mat-button-disabled{
            max-width: 100%;
            min-width: 100%;
        }
    /* END BUTTONS */

/* ============= bahamon.dev ============== */

.form-button-container {
    // text-align: center;
    margin-top: 1rem;
    display: flex;
    justify-content: space-evenly;
}

.form-button {
    background-color: var(--rojo-primario);
    font-weight: 800;
    color: white;
}

.form-button:disabled {
    background-color: rgba(238, 238, 238);
    color: rgba(171, 171, 171);
    border-color: rgba(208, 208, 208);
}

.close-dialog-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    border-color: transparent;
    background-color: transparent;
    color: red;

    &:focus-visible {
        border: none;
    }
}


.home-btn {
    background-color: #ffb507;
    color: black;
    font-weight: 800;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: var(--elevacion-2);
    transition: all 1s ease-in-out;
}

.mat-raised-button.home-btn {
    margin-top: 15px;
}

.home-btn:hover {
    cursor: pointer;
    background-color: var(--rojo-primario);
    color: white;
}


