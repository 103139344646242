/* I */
  figure img {
    width: 100%;
  }
  form._form_contact button {
      margin: 2rem auto 4rem;
  }
  .modal-header {
    border: 0;
  }
  ._modal_1 .modal-content {
    background-color: #55646E;
    color: #fff;
  }
  ._modal_1 .modal-content h2 {
    font-size: 2.2rem
  }
  ._modal_1 .modal-content h4 {
    font-size: 1.7rem
  }
  ._modal_1 .modal-content p {
    font-size: 0.8rem;
    line-height: 1.6;
  }
  ._modal_1 .modal-content a{
    color: inherit;
  }
  ._btn._1._outline{
    background: transparent;
    border: 1px solid #D81E04;
    color: #D81E04;
    font-weight: 600;
  }
  section._home._i{
    position: relative;
  }
  section._home._i ._i>figure {
    position: absolute;
    right: 0;
    top: 0;
    width: 60vw;
    height: 50vw;
  }
  section._home._i ._i ._title{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.1;
  }
  section._home._i ._i ._text {
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
    width: 530px;
    max-width: 100%;
  }
  section._home._i ._i>figure img{
    width:100%;
    height:100%;
    object-fit:contain;
  }
  section._home._i ._i {
    position: relative;
    display: flex;
    align-items: center;
  }
  section._home._i aside._form {
    position: absolute;
    top: 7%;
    right: 0;
    z-index: 3;
    width:100%;
  }
  section._home._i aside._form ._in {
    position: relative;
    height: 210vh;
    display: flex;
    justify-content: end;
  }

  section._home._i aside._form ._in ._stick{
    position: sticky;
    top: 18vh;
    height: max-content;
  }
  section._home._i ._i>div>.row>._1 {
    position: relative;
    z-index: 4;
  }


  main._contacto ._body .mat-form-field .mat-form-field-flex{
    background: #F6F5F8;
    border-radius: 1rem;
    padding: 0.3rem 1rem;
  }
  main._contacto ._body .mat-form-field-infix {
    padding: 0.5rem !important;
    margin: 0 !important;
    border-top: 0;
  }
  main._contacto .mat-form-field-appearance-legacy .mat-form-field-label{
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    color: #212529;
  }
  main._contacto ._body .mat-form-field-underline{
    display: none;
  }
  form._form._1 .mat-form-field-label,
  form._form._1 .mat-form-field-label span {
    color: #666666;
  }
  form._form._1 .mat-form-field-appearance-outline .mat-form-field-flex{
    min-height: 3rem;
    padding:0;
    margin-top:0;
  }
  form._form._1 .mat-form-field-infix {
    padding: 0;
    border: 0;
    max-width: 100%;
    min-height: 2rem;
  }
  form._form._1 .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, 
  form._form._1 .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label{
    transform: translateY(-2.4em) scale(0.75);
  }
  form._form._1 .mat-form-field-appearance-outline .mat-form-field-label{
    top: 3rem;
    font-size: 0.9rem;
  }
  form._form._1 .mat-form-field-label-wrapper{
    left: 1rem;
    top: -0.7rem;
  }
  form._form._1 .mat-form-field-appearance-outline .mat-select-arrow-wrapper{
    transform:translateY(0);
    height: 2.2rem;
  }
  form._form._1 input,
  form._form._1 .mat-select-value-text,
  form._form._1 textarea {
    min-height: 3rem;
  }
  form._form._1 .mat-select-value-text>span{
    min-height: 2rem;
    display: flex;
    align-items: center;
  }
  section._cotizacion._ii > div ._in ._btn{
    max-width:100%;
  }
  /* -------------------------------------------------- */

  section._home._i ._ii{
    position: relative;
  }
  section._home._i ._ii>div>.row>._1 {
    position: relative;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section._home._i ._ii>figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  section._home._i ._ii>figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
  }
  section._home._i ._ii ._head h1{
    font-family: "Roboto", sans-serif;
    color: #fff;
    margin-bottom: 4rem;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
  }
  section._home._i ._ii ._head{
    text-align: center;
    position: sticky;
    top: 6rem;
  }
  section._home._i ._ii ._body ._card-1 {
    background: #fff;
    border-radius: 2rem;
    padding: 3.5rem 3rem;
    text-align: center;
    width: 450px;
    margin: 0 auto;
    display: block;
    margin-bottom: 3rem;
    max-width: calc(100vw - 40px);
  }
  section._home._i ._ii ._body ._pre-card {
    position: sticky;
  }
  section._home._i ._ii ._body {
    position: relative;
  }

  section._home._i ._ii ._body ._card-1 ._in._1 {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    align-items: center;
  }
  section._home._i ._ii ._body ._card-1 ._in._1 h2 {
    margin-bottom: 0;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #2E373C;
    line-height: 1.2;
  }
  section._home._i ._ii ._body ._card-1 ._in._1>img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: contain;
  }
  section._home._i ._ii ._body ._card-1 ._in._1{
    margin-bottom: 1rem;
  }
  section._home._i ._ii ._body ._card-1 ._in._2 p{
    font-family: "Roboto", sans-serif;
    color: #2E373C;
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 0;
  }
  section._home._i ._ii ._body ._card-1 ._in._2 p a{
    font-family: 'Roboto', sans-serif;
    color: #2E373C;
    text-decoration: none;
  }
  /* --------------------------------------------------- */
  section._home._i ._iii ._brands {
    display: flex;
    grid-gap: 1rem;
    align-items: center;
  }
  section._home._i ._iii ._brands figure {
    margin-bottom: 0;
  }
  section._home._i ._iii ._brands ._brand figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  section._home._i ._iii ._brands ._brand figure {
    width: 13rem;
    height: 13rem;
  }
/* END I */
/* -------------------------------------------------- */
/* II */
  section._home._ii>figure {
    position: absolute;
    width: 50vw;
  }
  section._home._ii > figure img {
    width: 100%;
  }
  section._home._ii h1{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.3;
  }
  section._home._ii p {
    font-size: 0.8rem;
    line-height: 1.7;
  }
  section._home._ii figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  section._home._ii>div>.row>div>._in {
    align-items: center;
    height: 100%;
    display: flex;
    width: 100%;
  }
  section._home._ii>div>.row>div>._in figure{
    position: relative;
    overflow: hidden;
  }
  section._home._ii > div > .row > div > ._in figure:before,
  section._home._ii > div > .row > div > ._in figure:after {
    content: "";
    position: absolute;
    top: 0;
    height: 120%;
    width: 120%;
    background: radial-gradient(#ac0403, #ac040305);
    display: block;
    border-radius: 99rem;
    filter: blur(90px);
  }
  section._home._ii > div > .row > div > ._in figure:before{
    left: -50%;
    opacity: 0.3;
  }
  section._home._ii > div > .row > div > ._in figure:after{
    right: -50%;
    opacity: 0.2;
  }
  section._home._ii>figure {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0;
  }
  section._home._ii>div {
    position: relative;
    z-index: 2;
  }
  section._home._ii>div>.row>div>._in ._info {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
  }
  section._home._i ._ii ._body ._card-1 ._in._2 p a {
    font-weight: 800;
    text-decoration: underline;
  }
/* END II */
/* ------------------------------------------------- */
/* FORM */
  @media(min-width:1200px){
    form._form._1 {
      width: 500px;
      max-width: 45vw;
      padding: 2rem;
      background: #FFFFFF;
      border-radius: 2rem;
      box-shadow: 0 0 20px 5px #d5d5d5;
    }
  }
  @media(max-width:1199px){
    form._form._1 {
      width: 500px;
      max-width: 90vw;
      padding: 2rem;
      background: #FFFFFF;
      border-radius: 2rem;
      box-shadow: 0 0 20px 5px #d5d5d5;
    }
    .form-button-container{
      display:block !important;
    }
  }
  form._form input{
    transition:all 0.3s ease-in-out;
  }
  form._form input[aria-expanded="true"] {
    box-shadow: 0 4px 20px -2px #808080ab;
    transform:translateY(0.6rem);
  }
  form._form._1 ._head {
    text-align: center;
    margin-bottom: 1rem;
  }
  form._form._1 ._head h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
  }
  form._form._1 ._head ._steps {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    text-align: left;
    font-size: 0.76rem;
    justify-content: center;
    line-height: 1;
  }
  form._form._1 ._head ._steps ._step{
    opacity:0.6;
    color:#2E373C;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
  }
  section._home form._form._1 ._head ._steps ._step._1{
    opacity: 1;
  }
  form._form .mat-form-field {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  form._form .mat-form-field-wrapper {
    margin:0;
    padding:0;
  }
  form._form .mat-form-field-flex input,
  form._form .mat-select {
    border-radius: 1.1rem;
    background: #E9E9E9;
    padding: 0.5rem 1rem;
    width: 100%;
    margin-top: 1rem;
    font-size:0.9rem;
  }
  ._form .mat-form-field:focus .mat-form-field-flex {
    box-shadow: 0 0 10px -3px black;
  }
  form._form button {
    padding: 0.9rem 3rem;
    border-radius: 99rem;
    background: #D81E04 !important;
    color: #FFF !important;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    min-width: 20rem;
    max-width: 100%;
  }
  form._form .form-button-container {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
  form._form .mat-form-field-subscript-wrapper{
    top: 95%;
  }
  form._form ._foot p {
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    color: #848484;
    line-height: 1.1;
  }
  form._form ._foot p a{
    color: #848484;
    text-decoration: underline;
  }

  form._form ._foot {
    text-align: center;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap,
  .mat-form-field-appearance-outline .mat-form-field-outline-start, 
  .mat-form-field-appearance-outline .mat-form-field-outline-end{
    border:0 !important; 
  }
  ._form .mat-input-element{
    box-sizing: inherit;
  }
  ._form .mat-form-field-flex input {
    border-radius: 1rem;
    background: #E9E9E9;
  }

  section._identificacion._1 ._body ._items ._item-pro._2 ._item-descripcion {
    width: calc(50% - 0.5rem);
  }
  section._identificacion._1 ._body ._items ._item-pro._2 ._item-name {
    width: calc(50% - 0.5rem);
  }
  .fullwidth{
    width: 100% !important;
  }
/* END FORM */

/*NAVIGATION*/
  aside._supermenu {
    position: fixed;
    top: 0;
    left: -200%;
    z-index: 99;
    padding: 5rem 2rem;
    width: 600px;
    max-width: 100%;
    height: 100vh;
    background: #E9E9E9;
    transition:all 0.4s ease-in-out;
  }
  aside._supermenu._on{
    left:0;
  }
  aside._supermenu>._in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  aside._supermenu>._in ._items._1 a {
    display: block;
    padding: 1rem 0.4rem;
    text-decoration: none;
    color: #4b4b4b;
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1;
  }
  aside._supermenu>._in ._items ._icons {
    display: flex;
    grid-gap: 1rem;
      align-items: center;
      flex-wrap:wrap;
  }
/* -------------------------------------------------- */
/* ANIMATIONS */
  ._marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 13rem;
    overflow: hidden;
  }
  ._track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 100s linear infinite;
  }
  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }

/* END ANIMATIONS */
/* RESPONSIVE */
  @media(min-width:1200px){
    section._home._i ._i{
      min-height: 100vh;
    }
    section._home._i ._i ._title{
      font-size: 3rem;
      margin-bottom: 3rem;
    }
    section._home._i ._ii>div>.row>._1{
      padding: 20vh 0;
    }
    section._home._i ._iii {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    section._home._ii {
      position: relative;
      padding: 5rem 0;
    }
    section._home._ii h1{
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    section._home._i ._ii ._head h1{
      font-size: 4rem;
    }
    section._home._i ._ii ._body ._card-1 ._in._1 h2{
      font-size: 2.3rem
    }
    section._home._ii>div>.row>div>._in figure{
      border-radius: 4rem;
    }
    footer._footer>div>.row>._2 ._in ._flex._2 ._1 {
      width: 40%;
    }
  }
  @media(min-width:992px) and (max-width: 1199px){
    section._home._i ._i{
      min-height: 100vh;
    }
    section._home._i ._i ._title{
      font-size: 2.4rem;
      margin-bottom: 3rem;
    }
    section._home._i ._ii>div>.row>._1{
      padding: 20vh 0;
    }
    section._home._i ._iii {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    section._home._ii {
      position: relative;
      padding: 5rem 0;
    }
    section._home._ii h1{
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    section._home._i ._ii ._head h1{
      font-size: 4rem;
    }
    section._home._i ._ii ._body ._card-1 ._in._1 h2{
      font-size: 2.3rem
    }
    section._home._ii>div>.row>div>._in figure{
      border-radius: 4rem;
    }
    footer._footer>div>.row>._2 ._in ._flex._2 ._1 {
      width: 40%;
    }
  }
  @media(max-width:991px){
    section._home._ii h1{
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
    section._home._i ._i{
      padding:10rem 0;
    }
    section._home._i ._i ._title{
      font-size: 2rem;
      font-weight: 600;
    }
    section._home._i ._info {
      text-align: center;
    }
    section._home._i ._i ._text{
      margin:0 auto; 
    }
    section._home._i ._form-resp {
      margin: 4rem auto -66%;
      position: relative;
      z-index: 9;
      width: 100%;
    }
    section._home._i ._form-resp form {
      margin: 0 auto;
      width: 600px;
      max-width: 100%;
    }
    section._home._i ._i>figure{
      z-index:-1;
    }
    section._home._i ._iii {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    section._home._ii {
      position: relative;
      padding: 1rem 0;
    }
    section._home._ii>div>._1 {
      margin-bottom: 4rem;
    }
    section._home._ii>div>._2 {
      margin-bottom: 4rem;
    }
    section._home._i ._ii ._head h1{
      font-size: 2rem;
    }
    section._home._i ._ii ._body ._card-1 ._in._1 h2{
      font-size: 1.5rem;
      font-weight: 600;
    }
    section._home._ii>div>.row>div>._in figure{
      border-radius: 2rem;
    }
    footer._footer > div > .row > ._2 ._in ._flex figure._mapfre {
      width: 250px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  @media(max-width:991px) and (min-width:768px){
    section._home._i ._ii>div>.row>._1{
      padding: 45vw 0 5rem;
    }
    footer._footer>.container>.row>._2 {
      align-self: center;
    }
    footer._footer>.container>.row>._2 p{
      font-size: 0.8rem;
    }
    footer._footer>.container>.row>._2 ._flex._2 > ._1{
      width:50%;
    }
    footer._footer>.container>.row>._3{
      text-align: center;
    }
  }
  @media(max-width:767px){
    section._home._ii>div>._1>._2 {
      order: 1;
    }
    section._home._ii>div>._1>._1 {
      order: 2;
    }
    section._home._ii > div > .row > div > ._in {
      width: 400px;
      max-width: 100%;
      margin: 0 auto 3rem;
    }
    section._home._i ._ii>div>.row>._1{
      padding:40% 0 5rem;
    }
    footer._footer .row>div._1 {
      text-align: center;
    }
    footer._footer ._brand{
      margin:0 auto; 
    }
    footer._footer ul._icons{
      justify-content: center;
    }
    footer._footer > div > .row > ._2 ._in ._flex._2 {
      display: block;
      text-align: center;
    }
    footer._footer>div>.row>._2 ._in ._flex._2 ._1 {
      width: 100%;
    }
    footer._footer > div > .row > ._2 ._in ._flex._2 {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    footer._footer > div > .row > ._3 {
      text-align: center;
    }
  }
/* END RESPOSNIVE */







.background {
  position: absolute;
  background-image: url("../../img/bg-home-dos.jpg");
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 850px;
  max-height: 850px;
  transform: translateY(-200px);
  z-index: -2;
}

.mapfre-container.home {
  margin-top: 150px;
}

.especialidad-container {
  color: white;
  transform: translateY(6rem);
}

.home-form {
  margin-top: 2rem;
}

.flex-container.home {
  margin-bottom: 50px;
}

.titulos-home {
  width: 45%;
}

.titulos-home h1 {
  margin-bottom: 5px;
  font-size: 3rem;
  text-align: right;
  font-weight: 800;
  white-space: nowrap;
}

.titulos-home.blanco {
  color: white;
}

.lineas-container {
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
}

.franja {
  background-color: var(--rojo-primario);
  height: 2.7rem;
  width: 100%;
}

.linea {
  height: 2px;
  margin-top: 10px;
  width: 100%;
}
.rojo {
  background-color: var(--rojo-primario);
}

.amarillo {
  background-color: #ffb507;
}

.franja.beneficios,
.franja.contacto,
.franja.comentarios {
  position: relative;
  width: 100%;
  margin-top: 2px;
}

.beneficio-container {
  display: flex;
  margin-top: 16px;
}

.beneficio-descripcion {
  width: 80%;
  margin: 0 auto;
}

.beneficio-descripcion h3 {
  font-weight: 800;
  font-size: 18px;
  margin: 0;
}

.beneficio-descripcion p {
  font-size: 16px;
  margin: 0;
  line-height: 1.2;
}

.beneficio-icon {
  font-size: 4rem;
}

.contacto h2,
.contacto h1 {
  margin: 0;
}

.contacto h1 {
  color: var(--rojo-primario);
  font-weight: 800;
  margin-bottom: 5px;
}

.contacto p {
  color: var(--gris-500);
  margin: 0;
}

@media screen and (min-width: 769px) and (max-width: 1300px) {

  .titulos-home {
    width: 100%;
  }

  .titulos-home h1 {
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .background {
    min-height: 700px;
    max-height: 700px;
  }
}

@media screen and (min-width: 451px) and (max-width: 768px) {
  .background {
    height: 650px;
  }

  .especialidad-container {
    width: 70%;
    height: 500px;
    justify-content: flex-start;
  }

  .especialidad-container h1 {
    font-size: 1.7rem;
  }

  .home-form {
    margin-top: 0.375rem;
  }

  .titulos-home {
    width: 100%;
  }

  .titulos-home h1 {
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .background {
    min-height: 700px;
    max-height: 700px;
    transform: translateY(-200px);
    z-index: -2;
  }

  .titulos-home {
    width: 100%;
  }

  .titulos-home h1 {
    text-align: left;
    font-size: 2rem;
  }

  .especialidad-container {
    width: 95%;
    margin: 0 auto;
  }
}


section._identificacion._1 ._body ._title {
  text-align: center;
  padding: 0.5rem;
  background: #f1f1f1;
  border-radius: 3rem;
  margin-bottom: 2rem;
}
section._identificacion._1 ._body ._title h4 {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
} 
section._identificacion._1 ._body ._items ._item-info {
  display: flex;
  align-items: start;
  grid-gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
section._identificacion._1 ._body ._items ._item-info ._p {
  margin-bottom: 0;
}
section._identificacion._1 ._body ._items ._item-info ._icon{
  width: 3rem;
}
section._identificacion._1 ._body ._items ._item-info ._name {
  width: calc(100% - 4rem);
}
section._identificacion._1 ._body ._items ._item-info ._icon img {
  width: 1.5rem;
  margin: 0 auto;
  display: block;
  object-fit: contain;
  height: 1.5rem;
}
section._identificacion._1 ._body ._items ._item-info .mat-form-field-flex {
  padding: 0;
}
section._identificacion._1 ._body ._items ._item-info .mat-form-field-flex input {
  margin-top: 0;
  height: 2.5rem;
  background: transparent !important;
  background-color: transparent !important;
}
section._identificacion._1 ._body ._items ._item-info .mat-form-field-flex .mat-form-field-label-wrapper {
  left: 1rem;
}
section._identificacion._1 ._body ._items ._item-pro {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
section._identificacion._1 ._body ._items ._item-pro ._item-name {
  width: calc(30% - 0.5rem);
}
section._identificacion._1 ._body ._items ._item-pro ._item-descripcion {
  width: calc(70% - 0.5rem);
}
section._indentificacion ._body .row._2 {
  width: 700px;
  margin: 2rem auto;
}
section._indentificacion ._body .row._2 button {
  margin-bottom: 2rem;
}
section._identificacion._1 ._body ._items ._item-pro ._item-name p {
  font-size: 0.7rem;
  line-height: 1.1;
  font-weight: 600;
}
section._indentificacion ._body {
    width: 700px;
    margin: 0 auto 4rem;
    max-width: 100%;
}
@media(min-width:992px){
  section._identificacion._1 ._body ._items {
    width: 80%;
    max-width: 100%;
    margin: 0 auto;
  }

  section._home._i ._ii ._body ._pre-card._1,
  section._home._i ._ii ._body ._pre-card._2,
  section._home._i ._ii ._body ._pre-card._3{
    top: 20rem;
  }
}
@media(max-width:991px){
  section._home._i ._ii ._body ._pre-card._1,
  section._home._i ._ii ._body ._pre-card._2,
  section._home._i ._ii ._body ._pre-card._3{
    top: 13rem;
    margin-bottom: 2rem;
  }
  section._identificacion._1 ._body ._items {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  section._indentificacion ._body {
    width: 500px;
    margin: 0 auto 4rem;
    max-width: 100%;
  }
}
section._indentificacion form._form .mat-form-field-flex input{
}

.custom-radio-options.yes-no {
    background: #e9e9e9;
    border-radius: 99rem;
    position: relative;
    display: flex;
}
section._identificacion._1 .row._2 {
  width: 700px;
  max-width: 100%;
  margin: 2rem auto 4rem;
}
section._identificacion._1 .row._2 button{
  margin-bottom: 2rem;
}



/* NAVIGATION */
._actions_collapse{
  position: relative;
  width: max-content;
}
._actions_collapse .collapse {
  position: absolute;
  top: calc(100% - 2rem);
  left: 0;
}
button._action_button_services{
  position: relative;
  z-index: 1;
}
._actions_collapse .collapse {
  position: absolute;
  top: calc(100% - 2rem);
  left: 0;
  border: 1px solid #d81e04;
  border-radius: 1rem;
  padding: 3rem 1rem 1rem;
  text-align: center;
  background: #E9E9E9;
  z-index: 0;
  width: 100%;
}
._actions_collapse .collapse ._flex {
  display: flex;
  grid-gap: 0.5rem;
}
._actions_collapse .collapse ._flex ._icon{
  width:calc(33% - 0.25rem);
  cursor: pointer;
}
._actions_collapse .collapse ._flex ._icon img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}
._actions_collapse .collapse ._flex ._icon p {
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
  color: #666666;
  margin: 0;
}
button._btn._1._action_button_services._active {
  border-radius: 1rem 1rem 0 0;
}
/* END NAVIGATION */