.form-container {
  .mat-checkbox-layout {
    width: 100%;
    white-space: pre-wrap;
    text-align: left;
    margin-top: 0.8rem;

    .mat-checkbox-inner-container {
        margin: 0 1rem;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .form-container {
    .mat-checkbox-layout {
      margin-top: 0;
      .mat-checkbox-inner-container {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .form-container {
        .mat-checkbox-layout {
          margin-top: 0;
          .mat-checkbox-inner-container {
            margin-left: 10px;
        }
        }
      }
}
