/* ============= bahamon.dev ============== */

  footer._footer {
    position: relative;
    background: #E9E9E9;
    padding: 2rem;
  }
  footer._footer {
    position: relative;
    background: #E9E9E9;
    padding: 2rem;
  }
  footer._footer ul {
    list-style: none;
    padding: 0;
  }

  footer._footer a {
      text-decoration: none;
  }
  footer._footer ul {
    list-style: none;
    padding: 0;
  }

  footer._footer a {
    text-decoration: none;
  }
  footer._footer ul li a{
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
    color: #2E373C;
    display: block;
    margin-bottom: 0.3rem;
  }
  footer._footer p {
    margin-bottom: 0;
  }
  footer._footer>div>.row>._1 p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  footer._footer ._brand {
    display: block;
    width: 220px;
    max-width: 100%;
  }
  footer._footer ._brand img {
    width: 100%;
  }
  footer._footer>div>.row>._2 ._in .col-8 {
    align-self: center;
  }
  footer._footer>div>.row>._2 ._in .col-8 p {
    font-size: 1rem;
    line-height: 1.5;
  }
  footer._footer > div > .row > ._2 ._in .col-8 p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
  }
  footer._footer figure{
    margin-bottom: 0;
  }
  footer._footer figure img {
    width: 100%;
  }
  footer._footer figure._mapfre{
    width: 90%;
    max-width: 100%;
  }
  footer._footer>div>.row>._2 ._in>.row>div {
    align-self: center;
  }
  footer._footer>div>.row>._2 ._in>.row:last-of-type{
    margin-top: 1rem; 
  }
  footer._footer > div > .row > ._3 ._in {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
  }
  footer._footer ul._icons {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    margin-top: 2rem;
  }
  footer._footer>div>.row:last-of-type {
    margin-top: 2rem;
    text-align: center;
  }
  footer._footer ._pays {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
  footer._footer>div>.row>._2 ._in {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  footer._footer>div>.row>._2 ._in ._flex {
    display: flex;
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  footer._footer>div>.row>._2 ._in ._flex figure._ccb {
  }
  footer._footer>div>.row>._2 ._in ._flex figure._mapfre {
    width: 100%;
  }
  footer._footer>div>.row>._2 ._in ._flex._1>._1 {
    width: 25%;
  }
  footer._footer>div>.row>._2 ._in ._flex._1>._2 {
    width: 75%;
  }
  footer._footer ._note p {
    font-size: 0.8rem;
    line-height: 1.2;
    font-weight: 400;
    color:#2E373C;
  }
/* ============= bahamon.dev ============== */





.home-footer .footer-container {
    background-color: #cb240e;
}

.social-media.social-media-mobile {
    display: none;
}
.footer-container {
    padding: 1rem auto;
    background-color: #5f5f5f;
    display: flex;
    color: white;
    
    p {
        margin: 0;
    }
}

.footer-column {
    width: 20%;
}

.footer-content {
    justify-content: center;
    flex-wrap: wrap;
}

.atencion-title {
    font-weight: 700;
}

.atencion-phone {
    font-size: 2rem;
    margin: 5px 0 !important;
}

.lineas-atencion,
.horarios-atencion,
.sede,
.social-media {
    width: 300px;
    margin: 1rem 0;
}

.social-media {
    font-size: 3rem;
    span {
        margin: 0.5rem;
    }
}
.social-media-icon {
    color: white;
    text-decoration: none;
    &:hover {
        color: whitesmoke;
        text-decoration: none;
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .footer-container {
        font-size: 12px;
        line-height: 18px;
    }

    .footer-content {
        padding: 0 1.5rem;
        justify-content: flex-start;
    }

    .lineas-atencion,
    .horarios-atencion,
    .sede,
    .social-media {
        margin: 5px 0;
    }

    .social-media {
        display: none;
    }

    .social-media.social-media-mobile {
        display: block;
    }

    .footer-column {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .footer-content {
        width: 280px;
        padding: 0 1rem;
    }
}
