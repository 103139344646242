// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'theme.scss';
@import '~@splidejs/splide/dist/css/splide.min.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
// @import "mapfre-theme.scss";
// Plus imports for other components in your app.

/* Fuente */
@font-face {
    font-family: "Raleway";
    src: url("../font/raleway-v14-latin-900.woff") format('woff');
    font-weight: 900;
}

@font-face {
    font-family: "Raleway";
    src: url("../font/raleway-v14-latin-800.woff") format('woff');
    font-weight: 800;
}
@font-face {
    font-family: "Raleway";
    src: url("../font/raleway-v14-latin-regular.woff") format('woff');
    font-weight: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../font/raleway-v14-latin-300.woff") format('woff');
    font-weight: 300;
}

$custom-typography: mat.define-typography-config(
    $font-family: "Raleway",
    $headline: mat.define-typography-level(32px, 48px, 900),
    $body-1: mat.define-typography-level(16px, 24px, 400)
);

@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mapfre-primary: mat.define-palette($primary, red-1, red-2, red-3 );
$mapfre-accent: mat.define-palette($accent, yellow-1, yellow-2, yellow-3);

// The warn palette is optional (defaults to red).
$mapfre-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$mapfre-theme: mat.define-light-theme($mapfre-primary, $mapfre-accent, $mapfre-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mapfre-theme);

/* You can add global styles to this file, and also import other style files */

.collapse:not(.show) {
    display: none;
}
.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
}

.enlace-primario {
    text-decoration: underline;
    color: red;
}

.no-m{
  margin:0;
}
.no-m>div{
  padding:0;
}
    


._w-100{
    font-weight: 100;
}
._w-200{
    font-weight: 200;
}
._w-300{
    font-weight: 300;
}
._w-400{
    font-weight: 400;
}
._w-500{
    font-weight: 500;
}
._w-600{
    font-weight: 600;
}
._w-700{
    font-weight: 700;
}
._w-800{
    font-weight: 800;
}
._w-900{
    font-weight: 900;
}