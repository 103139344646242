/* bahamon.dev */
  section._cotizacion._i ._head h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
  }
  section._cotizacion._i ._head {
    margin-bottom: 4rem;
  }
  section._cotizacion._i ._body ._item:not(._3) ._title {
    width: 100%;
    text-align: center;
    background: #E9E9E9;
    border-radius: 99rem;
    padding: 0.5rem 1rem;
  }
  section._cotizacion._i ._body ._item ._title h3 {
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  ._flex{
    display: flex;
  }
  section._cotizacion._i ._body ._flex {
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
  section._cotizacion._i ._body ._flex p {
    margin-bottom: 0;
  }
  section._cotizacion._i ._body ._item._2 ._content {
    padding: 2rem 0;
  }
  section._cotizacion._i ._body ._flex > div{
    position: relative;
  }
  section._cotizacion._i ._body ._flex p._h {
    display: flex;
    grid-gap: 1rem;
  }
  section._cotizacion._i ._body ._item ._content ._ii{
    width: 100%;
    position: relative;
    justify-content: start;
  }
  ._popover-brow h4 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: block;
  }
  
  ._popover-brow p {
    margin-bottom: 1rem !important;
    display: block;
    font-size: 0.9rem;
    line-height: 1;
  }
  ._popover-brow {
    position: absolute;
    left: 103%;
    width: 350px;
    max-width: 40vw;
    background: #55646E;
    padding: 1rem;
    border-radius: 1rem;
    color: #fff;
    z-index: 3;
    display: none;
  }
  @media(max-width: 991px){
    ._popover-brow{
      left: initial;
      right: 103%;
    }
  }
  ._popover{
    position: relative;
  }
  ._popover-brow.on{
    display: block;
  }
  section._cotizacion._i ._body ._flex>div {
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
  }
  section._cotizacion._i ._body ._flex:last-of-type {
    margin-bottom: 0;
  }
  section._cotizacion._i ._body ._form {
    width: 100%;
    display: flex;
  }
  section._cotizacion._i ._body ._form form {
    width: 80%;
    display: block;
    border: 0;
  }
  section._cotizacion._i ._body ._form ._select {
    padding: 1rem;
    outline: none;
    border: 0 !important;
    border-radius: 99rem;
    width: 100%;
    background: #e9e9e9;
  }
  section._cotizacion._i ._body ._flex span._popover {
    width: 30px;
    height: 30px;
    background: #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 99px;
    min-width: 30px;
  }
  section._cotizacion._i ._body ._flex span._popover i {
    font-style: inherit;
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 5;
    text-align: center;
    margin-bottom: 0;
  }
  section._cotizacion._i ._body ._item._3 ._flex ._data{
    display: block;
    text-align: center;
  }
  section._cotizacion._i ._body ._item._3 ._flex ._data ._title {
    padding: 0.5rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
  }
  section._cotizacion._i ._body ._item._3 ._flex ._data ._price {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  section._cotizacion._i ._body ._card p.costo-mapfre {
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1;
    padding: 0;
  }
  section._cotizacion._i ._body ._card ._btn {
    margin: 2rem auto;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-transform: none;
  }
  section._cotizacion._i ._body ._card ._descripcion {
    font-size: 0.8rem;
    line-height: 1.3;
    width: 80%;
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  }

  section._cotizacion._ii {
    position: relative;
    padding: 4rem;
  }

  section._cotizacion._ii>figure {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
      mix-blend-mode: multiply;
  }

  section._cotizacion._ii>figure>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      background-blend-mode: multiply;
      mix-blend-mode: multiply;
  }

  section._cotizacion._ii>figure:before {
      content: '';
      position: absolute;
      z-index: 2;
      inset: 0;
      height: 100%;
      width: 100%;
      background: #ac040385;
  }

  section._cotizacion._ii>div {
    position: relative;
    z-index: 3;
  }

  section._cotizacion._ii>div ._in {
    color: #FFF;
  }

  section._cotizacion._ii>div ._in h3 {
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
  }

  section._cotizacion._ii>div ._in ._btn {
    display: block;
    text-decoration: none;
    border-radius: 9rem;
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
    width: max-content;
    padding: 1rem 3rem;
    margin: 1rem auto 0;
  }
  ._formulario .mat-form-field .mat-form-field-infix {
    display: flex;
  }
  ._formulario .mat-form-field .mat-form-field-infix mat-select {
    width: 23%;
    position: relative;
  }
  ._formulario .mat-form-field .mat-form-field-infix mat-select:before {
    // content: 'Tipo';
    // position: absolute;
    // font-size: 0.8rem;
    // font-family: 'Roboto', sans-serif;
  }
  ._item-formulario._1 .mat-form-field span.mat-form-field-label-wrapper {
    left: 1rem;
    top: -9px !important;
    padding-top: 0 !important;
  }
  ._item-formulario._1 .mat-form-field .mat-form-field-flex mat-select {
    border-radius: 0.2rem 0 0 0.2rem;
    height: 3rem;
    margin-top: 0.5rem;
  }
  ._item-formulario._1 .mat-form-field input {
    margin-top: 0;
    height: 3rem;
    background: transparent;
    border-radius: 0 0.3rem 0.3rem 0;
    border: 1px solid #e9e9e9;
  }
  ._item-formulario._1 .mat-form-field-type-mat-select {
    width: 20%;
  }
  ._item-formulario._1 .mat-form-field-type-mat-input {
    width: 80%;
  }
  ._item-formulario._1 .mat-form-field .mat-form-field-flex {
    padding: 0;
  }
  ._item-formulario._1 .mat-form-field .mat-form-field-flex mat-select {
    border-radius: 0.2rem 0 0 0.2rem;
  }
  ._item-formulario {
    display: flex;
    align-items: center;
  }
  ._item-formulario._2 .mat-form-field-flex {
    position: relative;
    padding: 0;
  }
  ._item-formulario._2 .mat-form-field-flex .mat-form-field-suffix {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  ._item-formulario._2 .mat-form-field-flex .mat-form-field-suffix mat-datepicker-toggle {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }
  ._item-formulario._2 .mat-form-field-flex .mat-form-field-suffix mat-datepicker-toggle button {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  ._item-formulario._2 input {
    background: transparent !important;
    border-radius: 0.3rem !important;
    border: 1px solid #e9e9e9;
    height: 3rem;
    width: 100%;
  }
  ._item-formulario._2 .mat-form-field-label-wrapper {
    top: 5px;
    left: 1rem;
  }
  ._identificacion .mat-form-field-appearance-outline .mat-form-field-infix{
    padding:0;
    border-top:0 !important;
  }
  ._form ._cont-item {
    display: flex;
    grid-gap: 1rem;
  }
  ._form ._cont-item>._1 {
    padding-top: 1.5rem;
  }
  ._indentificacion ._header {
    padding-top: 8rem;
    padding-bottom: 4rem;
  }
  ._indentificacion ._header h2._title {
    font-size: 2.3rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }
  ._indentificacion ._header p {
    font-size: 1.1rem;
  }
  section._cotizacion._i ._body ._flex span._popover i {
    line-height: 2.5;
    font-weight: 600;
  }
  @media(min-width:992px){
    section._cotizacion._i {
      padding-top: 10rem;
      text-align: center;
      padding-bottom: 5rem;
    }
    section._cotizacion._i ._body ._card {
      box-shadow: 0 0 20px 10px #8080805e;
      border-radius: 2rem;
      padding: 3rem;
      width: 500px;
      margin: 0 auto;
      max-width: 100%;
    }
    section._cotizacion._i ._body ._item._1 ._content {
      padding: 2rem 3rem;
    }
    section._cotizacion._i ._body ._item._2 ._flex > ._i {
      width: 55%;
    }
    section._cotizacion._i ._body ._item._2 ._flex > ._ii {
      width: 45%;
    }
    section._cotizacion._i ._body ._flex>._i{
      width: 40%;
    }
    section._cotizacion._i ._body ._flex>._ii{
      width: 60%;
    }
  }
  @media(max-width:1200px){
    section._cotizacion._i ._body {
      width: 700px;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  @media(max-width:1200px) and (min-width:992px){
    section._home._i form._form._1{
      max-width: 38vw;
      padding: 2rem;
    }
    section._cotizacion._i ._body .col-xl-6._1 {
      margin-bottom: 3rem;
    }
  }
  @media(max-width:991px){
    section._cotizacion._i {
      padding-top: 10rem;
      text-align: center;
    }
    section._cotizacion._i ._body > .row > ._1{
      padding-bottom:5rem;
    }
    form._form._1 ._head ._steps{
      grid-gap: 1rem !important;
    }
    section._cotizacion._i ._head h2{
      width: 500px;
      font-weight: 600;
      max-width: 100%;
      margin: 0 auto ;
    }
    section._cotizacion._i ._body>.row>._1 {
      order: 2;
      background: #E9E9E9;
    }
    section._cotizacion._i ._body>.row>._2 {
      order: 1;
      border-radius: 2rem 2rem 0 0;
      background: #E9E9E9;
      padding: 3rem 2rem;
    }

    section._cotizacion._i ._body ._card ._descripcion{
      font-size:1rem;
      width: 500px;
      max-width: 100%;
    }
    section._cotizacion._i ._body ._card ._btn{
      width: 500px;
      max-width: 100%;
    }
    section._cotizacion._i ._body ._item._1 ._content{
      width: 500px;
      max-width: 100%;
      padding: 2rem 0;
      margin:0 auto;
    }
    section._cotizacion._i ._body ._flex span._popover{
      background: #FFFFFF;
    }
    section._cotizacion._i ._body ._item._2 ._flex > ._i,
    section._cotizacion._i ._body ._item._2 ._flex > ._ii {
      width: 100%;
    }
    section._cotizacion._i ._body ._item._2 ._flex > ._i {
      justify-content: center;
    }
    section._cotizacion._i ._body ._item._3 ._flex:last-of-type ._data {
      width: 100%;
    }
    section._cotizacion._i ._body ._item._3 ._flex:last-of-type {
      flex-wrap: wrap;
    }
    section._cotizacion._i ._body ._item._2 ._flex > div {
      justify-content: center;
    }
  }
  @media(max-width:991px) and (min-width:768px){
    section._cotizacion._i ._body ._flex>._i {
      width: 40%;
    }
    section._cotizacion._i ._body ._flex>._ii {
      width: 60%;
    }
  }
  @media(max-width:767px){
    section._cotizacion._i ._body ._flex{
      flex-wrap:wrap;
    }
    section._cotizacion._i ._body ._flex>._i {
      min-width: 40%;
    }
    section._cotizacion._i ._body ._flex>._ii {
      min-width: 60%;
    }
  }



  button.mat-button-disabled {
    opacity: 0.3;
  }
  section._cotizacion._i ._body ._form mat-form-field {
    width: 100%;
  }
/* end bahamon.dev */

.lineas-container.cotizacion {
  top: 350px;
}

.flex-container.cotizacion {
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
}

.nombre-seguro p,
.nombre-seguro h1 {
  margin: 0;
}
.nombre-seguro h1 {
  font-weight: 800;
  line-height: 1.2;
}
.nombre-seguro h1:first-child {
  font-size: 24px;
  color: var(--gris-700);
}

.nombre-seguro h1:last-child {
  margin-top: 10px;
  color: var(--gris-900);
  font-size: 26px;
}

.costo-normal-seguro p {
  margin: 0;
  width: 50%;
  text-align: center;
}

.costo-normal-seguro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.cotizacion-contenedor {
  width: 80%;
  margin: 150px auto;
}

.form-button-container.cambiar-rc,
.form-button-container.cotizacion {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin: 0;
}
.form-button-container.cambiar-rc button:last-child,
.form-button-container.cotizacion button:last-child {
  margin-top: 10px;
}

.contenedor-costos {
  width: 360px;
  margin: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.3rem;
  box-shadow: var(--elevacion-3);
}

.seguro-header h2 {
  font-weight: 700;
  font-size: 1.7rem;
  height: 65px;
}

.costos-seguro p {
  margin: 2px auto;
  text-align: center;
}

.costos-seguro {
  display: flex;
  flex-direction: column;
}

.costo-normal {
  text-decoration: line-through;
  color: #ffa407;
  font-weight: 800;
  font-size: 1.8rem;
  margin: 10px 0;
}

.costo-mapfre {
  font-size: 3rem;
  font-weight: 600;
  margin: 20px 0;
  padding: 1.5rem 1rem;
  border-radius: 3rem;
  background-color: #ffc156;
}

.hint {
  color: var(--gris-600);
  margin: 0;
  font-size: 14px;
}

.hint.documento {
  color: black;
  line-height: 1;
  height: auto;
  margin: 5px 0;
}

.costo-cuota {
  font-weight: 800;
  font-size: 1.8rem;
  padding: 5px 0;
  background-color: var(--gris-100);
  border-radius: 3rem;
  margin: 10px 0;
}

.detalles-content,
.detalles-tabs,
.detalles-header {
  margin: 1rem 0;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: var(--elevacion-3);
}

.detalles-tabs {
  width: 60%;
  margin: 0 auto;
}

.subtitulo-tab {
  margin: 0 !important;
}

.costos,
.detalles-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mat-tab-body {
  margin-top: 16px;
}

.seguro {
  text-align: left;
  color: var(--gris-700);
}

.cambiar-rc-input {
  width: 100%;
}

.img-mapfre {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.logo-mapfre-seguro {
  width: 150px;
  margin-left: 16px;
}

/* RESUMEN */

.step-grid.resumen {
  grid-template-rows: 150px calc(100vh - 200px) 150px auto;
}

.icono-resumen {
  font-size: 4rem;
  color: var(--rojo-primario);
}

.nota-resumen {
  margin-top: 1rem;
  background-color: var(--amarillo-100);
}

.nota-resumen h2,
.nota-resumen ul,
.nota-resumen p {
  margin: 0;
  color: var(--amarillo-900);
}

.nota-resumen h2 {
  font-weight: 800;
}

.warning-alert {
  background-color: var(--amarillo-100);
  border-radius: 3px;
  padding: 14px;
  margin: 12px 0;
  color: var(--amarillo-900);
  border-left: 5px solid var(--amarillo-900);
}

.warning-alert p {
  margin: 0;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.2;
}

.warning-alert p::before{
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  content: "\e001";
  margin-right: 12px;
  font-size: 24px;
}

@media screen and (min-width: 401px) and (max-width: 768px) {
  .costo-seguro {
    padding: 0.4rem 1rem;
    font-size: 1rem;
  }

  .costo-total {
    margin-left: 0.32rem;
  }
  .valor-seguro-alseguros {
    align-items: flex-start;
  }

  .cuotas-text,
  .valor-cuota {
    text-align: center;
  }

  .valor-cuota {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .table {
    border: none;
    width: 300px;
  }

  .table,
  .table caption,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    text-align: left;
  }

  .table thead,
  .table th,
  .table tr:first-child {
    display: none;
    visibility: hidden;
  }

  .table tr {
    background-color: white;
    margin-bottom: 8px;
    border: 1px solid var(--gris-200);
  }

  .table td:nth-child(2n + 1),
  .table tr,
  .table td {
    width: 280px;
    text-align: left;
  }

  .table th,
  .table td {
    border: none;
  }
}

@media screen and (min-width: 401px) and (max-width: 768px) {
  .costo-normal-seguro.desktop {
    display: none;
  }

  .costo-normal-seguro.mobile {
    width: 100%;
  }
  .cotizacion-contenedor {
    width: 95%;
    margin: 75px auto 10px auto;
  }

  .lineas-container.cotizacion {
    top: 220px;
  }

  .lineas-container.cotizacion > .franja {
    height: 3rem;
  }

  .nombre-seguro h1 {
    font-size: 28px;
    line-height: 1;
  }

  .contenedor-seguro {
    width: 300px;
  }

  .detalle-seguros-container {
    margin: 0 15px;
    height: 150px;
  }

  .costo-mapfre {
    font-size: 2.3rem;
  }

  .costos {
    justify-content: center;
  }

  .detalles-tabs {
    width: 600px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 400px) {
  .img-mapfre {
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .nombre-seguro h1:first-child {
    font-size: 20px;
    white-space: nowrap;
  }
  
  .nombre-seguro h1:last-child {
    font-size: 24px;
  }

  .logo-mapfre-seguro {
    width: 110px;
    margin-left: 0;
    margin-top: 5px;
  }

  .seguro {
    display: none;
  }

  .cotizacion-contenedor {
    width: 95%;
    margin: 55px auto 10px auto;
  }
  .detalles-tabs {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 350px) {
  .detalles-tabs {
    width: 270px;
    margin: 0 auto;
  }
}
