.screen-background.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--rojo-primario);
  width: 100%;
  height: 100vh;
  text-align: left;
  background-image: url('../../img/bg-step.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-red-screen {
    width: 400px;
    margin-bottom: 3rem;
}

.error-title {
  font-size: 8rem;
  font-weight: 900;
  margin-bottom: 10rem;
  height: 80px;
  color: white;
}

.error-text {
  font-size: 2rem;
  width: 500px;
  line-height: 2.5rem;
  color: white;
}
.mat-raised-button.error-button {
  padding: 0.6rem 1.5rem;
}
.error-button {
  color: var(--rojo-primario);
  display: flex;
  flex-direction: row;
  align-items: center;

  mat-icon {
    margin-right: 5px;
    transform: translateY(-3px);
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .screen-background.error-page {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .img-red-screen {
      display: none;
    }
  }

  .error-title {
    font-size: 4rem;
    height: 40px;
  }

  .error-text {
    width: 320px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
